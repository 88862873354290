import React from "react";
import { Link } from "react-router-dom";

//Images
import Icon9 from "./../../assets/images/whatwedo/fountainwall.jpg";
import Icon10 from "./../../assets/images/whatwedo/windowanddoor.JPG";
import Icon11 from "./../../assets/images/whatwedo/sideentarce.JPG";
import Icon12 from "./../../assets/images/whatwedo/stampedCon.JPG";
import Icon13 from "./../../assets/images/whatwedo/door.JPG";
import pic1 from "./../../assets/images/about/pic1.jpg";
import support1 from "./../../assets/images/icons/support1.png";

const cardData = [
  {
    image: Icon12,
    title: "Stamped Concrete",
    decrp: "Stamped concrete is a versatile and stylish option for enhancing outdoor spaces. It mimics the look of natural stone, brick, or wood, offering durability and low maintenance. Ideal for patios, walkways, and driveways, stamped concrete adds visual appeal and value to any property with its customizable patterns and colors.",
  },
  { image: Icon10, title: "Exposed Concrete" ,decrp:"Exposed concrete is a modern and durable finish that reveals the natural texture of the aggregate within the concrete. It's ideal for creating a sleek, minimalist aesthetic in both indoor and outdoor spaces. This low-maintenance option provides excellent slip resistance, making it perfect for driveways, patios, and pool decks."},
  { image: Icon9, title: "Foundation Walls",decrp:"Foundation walls are the essential structural elements that support and stabilize a building, transferring the load from the structure to the ground. They are typically constructed from reinforced concrete or masonry and are designed to withstand the pressure from soil and water. Properly built foundation walls ensure the longevity and safety of any building by preventing settlement, cracking, and moisture infiltration." },
  { image: Icon11, title: "Side Entrances",decrp:"Side entrances offer convenient access to your home or building, providing a functional and often more discreet entry point. They are ideal for daily use, helping to manage foot traffic and maintain the cleanliness of the main entrance. Side entrances can be designed to blend seamlessly with the overall architecture, adding both practicality and aesthetic value to your property." },

  { image: Icon13, title: "Windows and Doors" ,decrp:"Windows and doors are critical elements in any building, serving both functional and aesthetic purposes. They provide natural light, ventilation, and access while enhancing the overall design and energy efficiency of a space. Well-chosen windows and doors can improve security, reduce energy costs, and increase the value of your property. With a wide variety of styles, materials, and finishes available"},
];

function OneStop() {
  return (
    <>
      {cardData.map((item, ind) => (
        <div className="col-xl-4 col-md-6 m-b60" key={ind}>
          <div className="icon-bx-wraper style-3 text-center">
            <img src={item.image} alt="" />

            <div className="icon-content">
              <h4 className="title">{item.title}</h4>
              <p className="m-b0">
             {item.decrp}
              </p>
            </div>
          </div>
        </div>
      ))}

      <div className="col-xl-4 col-md-6 m-b60">
        <div
          className="icon-bx-wraper style-4"
          style={{ backgroundImage: "url(" + pic1 + ")" }}
        >
          <div className="inner-content">
            <div className="icon-media m-b30">
              <img src={support1} alt="" />
            </div>
            <div className="icon-content">
              <Link to={"/contact-us"} className="btn btn-primary">
                Call Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default OneStop;
