import React from 'react';
import {Link} from 'react-router-dom';
//Images
import blog1 from './../../assets/images/blog/pic1.jpg';
import blog2 from './../../assets/images/blog/pic2.jpg';
import avatar1 from './../../assets/images/avatar/avatar1.jpg';
import avatar2 from './../../assets/images/avatar/avatar2.jpg';
import { Rating } from '@mui/material';

const updatesBlog = [
    {image1: blog1, image2: avatar1, title:'Guys are so professional and i like their work',by:"Jerry",value:4},
    {image1: blog2, image2: avatar2 , title:'Love thier work ',by:"Methew",value:5},
    
];

function RecentNews(){
    return(
        <>
            {updatesBlog.map((data, ind)=>(
                <div className="dz-card style-1 blog-half m-b30" key={ind}>
                   
                    <div className="dz-info">
                        <div className="dz-meta">
                            <ul>
                                <li className="post-author">
                                    <Link to={"#"}>
                                        <img src={data.image2} alt=""  className="me-2"/>
                                        <span>By {data.by}</span>
                                    </Link>
                                </li>
                                <li className="post-date"><Link to={"#"}> 12 May 2022</Link></li>
                            </ul>
                        </div>
                      
                        <p className="m-b0">{data.title}</p>
                        <Rating readOnly value ={data.value}/>
                    </div>
                </div>
            ))}
        </>
    )
}
export default RecentNews;