import React, {useEffect, useState} from 'react';
import {NavLink, Link} from 'react-router-dom';

import Logo from './../assets/logo.png';
import LogoWhite from './../assets/logowhite.png';

function Header(){

    /* for sticky header */
	const [headerFix, setheaderFix] = React.useState(false);
	useEffect(() => {
		window.addEventListener("scroll", () => {
			setheaderFix(window.scrollY > 50);
		});
	}, []); 

    const [sidebarOpen, setSidebarOpen] = useState(false);	
    const [showMenu, setShowMenu] = useState(false);	 
    const linkStyle = {
         // Set the default color
      };
    
      const activeLinkStyle = {
        fontWeight: '100', // Make the text bold
        color: 'red', // Change the text color to red for the active link
      };
     
    return(
        <>
            <header className="site-header mo-left header header-transparent style-1">
                <div className={`sticky-header main-bar-wraper navbar-expand-lg ${headerFix ? "is-fixed" : ""}`}>
                    <div className="main-bar clearfix">
                        <div className="container clearfix">
                            <div className="logo-header">
                                <Link to={"/"} className="logo-dark"><img src={Logo} alt="" /></Link>
                                <Link to={"/"} className="logo-light"><img src={LogoWhite}  alt="" /></Link>
                            </div>
                            
                            <button  type="button"
                                className={`navbar-toggler  navicon justify-content-end ${sidebarOpen ? 'open' : 'collapsed' }`} 
                                onClick={()=>setSidebarOpen(!sidebarOpen)}
                            >
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>                            
                         
                                
                            <div className={`header-nav navbar-collapse collapse justify-content-end ${sidebarOpen ? "show" : ""}`} id="navbarNavDropdown" >
                                <div className="logo-header mostion">
                                    <NavLink to={"#"} className="logo-dark"><img style={{borderRadius:'16px'}} src={Logo} alt="" /></NavLink>
                                </div>                            
                                <ul className="nav navbar-nav navbar">
                                    <li><NavLink exact to="/" >Home</NavLink></li>
                                    <li><NavLink exact to="about-us" >About Us</NavLink></li>
                                    <li><NavLink exact to="/review" >Reviews</NavLink></li>
                                    <li><NavLink exact to="/contact-us" >Contact Us</NavLink></li>
                                </ul>                               
                            
                                <div className="header-bottom">
                                    <div className="dz-social-icon">
                                        <ul>
                                            <li><a target="_blank" className="fab fa-instagram" rel="noreferrer" href="https://instagram.com/pinewoodconstruction_?igshid=NGVhN2U2NjQ0Yg=="></a></li>
                                            <li><a target="_blank" className="fab fa-tiktok" rel="noreferrer" href="https://www.tiktok.com/@pinewoodconstruction?_t=8hPaqghwty1&_r=1"></a></li>{" "}
                                        </ul>
                                    </div>	
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>

                </div>
            </header>
        </>
    )
}
export default Header;