import React from 'react';
import { Link} from 'react-router-dom';

//images
import shape1 from './../assets/images/home-banner/shape1.png';
import bgimage from './../assets/images/banner/back.jpg';
import logowhite from './../assets/logowhite.png';
import flags from './../assets/images/footer/world-map-with-flags1.png';
import { MdEmail } from "react-icons/md";
import { FaPhoneSquareAlt } from "react-icons/fa";

function Footer(){
    return(
        <>
            <footer className="site-footer style-1" id="footer">
                <img className="bg-shape1" src={shape1} alt="" />
                <div className="footer-top background-luminosity" style={{backgroundImage: "url("+ bgimage +")"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-12 col-md-12" >
                                <div className="widget widget_about">
                                    <div className="footer-logo logo-white">
                                        <Link to={"/"}><img src={logowhite} alt="" /></Link>
                                    </div>
                                    <p>Pinwood Construction, where craftsmanship meets innovation. We specialize in building timeless structures that stand as a testament to quality and precision. Our commitment to excellence is matched only by our dedication to turning your vision into reality.</p>
                                    <div className="dz-social-icon transparent space-10">
                                        <ul>
                                            <li><a target="_blank" className="fab fa-instagram" href="https://instagram.com/pinewoodconstruction_?igshid=NGVhN2U2NjQ0Yg=="></a></li>{" "}
                                            <li><a target="_blank" className="fab fa-tiktok" href="https://www.tiktok.com/@pinewoodconstruction?_t=8hPaqghwty1&_r=1"></a></li>{" "}
                                           
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6" >
                                <div className="widget widget_services">
                                    <h4 className="widget-title">Other Links</h4>
                                    <ul>
                                        <li><Link to={"/"}>Home</Link></li>
                                        <li><Link to={"/about-us"}>About Us</Link></li>
                                        <li><Link to={"/review"}>Reviews</Link></li>
                                     
                                                     <li><Link to={"/contact-us"}>Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-5 col-sm-6" >
                                <div className="widget recent-posts-entry">
                                    <h4 className="widget-title">Contact Details</h4>
                                    <div className="widget-post-bx">
                                        <div className="widget-post clearfix">
                                            <div className="dz-info">
                                                <h6 className="title"><FaPhoneSquareAlt />{" "} Phonenumber</h6>
                                                <span className="post-date" style={{fontWeight:'400',color:'white'}}><a href="tel:+16479954462"style={{fontWeight:'400',color:'white'}}> +16479954462</a></span>
                                            </div>
                                        </div>
                                        <div className="post-separator"></div>
                                        <div className="widget-post clearfix">
                                            <div className="dz-info">
                                              <h6 className="title"><MdEmail  /> {"  "}Email</h6>
                                              <span className="post-date" > <a href="mailto:pinewoodconstruction62@hotmail.com"style={{fontWeight:'400',color:'white'}}>pinewoodconstruction62@hotmail.com</a></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-sm-12" >
                                <div className="widget widget_locations">
                                    <h4 className="widget-title">Location</h4>
                                    <div className="clearfix">
                                        <h6 className="title">Brampton, Ontario, Canada</h6>
                                       
                                        <img src={flags} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="footer-bottom text-center">
                    <div className="container">
                        <span className="copyright-text">Copyright © 2023 <a href="https://pinewoodConstruction.ca/" target="_blank">Pinewood Construction</a>. All rights reserved.</span>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer;