import React, { useState } from 'react';
import {useNavigate} from 'react-router-dom';

import PageLayout from './../layouts/PageLayout';
import axios from 'axios';


import emailjs from "@emailjs/browser"
import { emailValidation, firstNameValidation, noSpaceValidation, phoneOnChange, phoneValidation } from '../validation';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from "sweetalert"

function ContactUs(){
    const [firstName,setFirstName] = useState("")
    const[lastName,setLastName] =useState("")
    const [email,setEmail] = useState("")
    const [phonenumber,setPhonenumber] = useState()
    const [ message,setMessage] = useState("")
    const [isSending,setIsSending] = useState(false)
    const[isFirstNameError,setIsFirstNameError] = useState(false)
    const [isEmailError,setIsEmailError] = useState(false)
    const [isPhonenumberError,setIsPhonenumberError] = useState(false)
    const [isMessageError,setIsMessageError] = useState(false)
    const nav = useNavigate();
    const submitHandler = (e) => {
        e.preventDefault();
        nav("/");
    };
   
    function sendMail (e){
        e.preventDefault()
        if(firstName.length > 2 && emailValidation(email) && phoneValidation(phonenumber)  ){
            setIsSending(true)
        const templateParams = {
         firstName : firstName,
     lastName:lastName,
     email : email,
     phonenumber:phonenumber,
     message : message
    };
    
    
            emailjs.send('service_bkqz17m', 'template_8yd1yer',templateParams, 'Hdyje0b_WLKyH8C2y')
        .then((result) => {
            setIsSending(false)
            Swal({
                title: "Thanks",
                text: "We will get back to you ASAP!",
                icon: "success"
              });
          setEmail("")
          setFirstName("")
          setLastName("")
          setMessage("")
          setPhonenumber("")
        }, (error) => {
            setIsSending(false)
          console.log(error.text);
        });
        }else{
            if(!firstNameValidation(firstName)){
                setIsFirstNameError(true)
            }else if(!emailValidation(email)){
                setIsEmailError(true)
            }else if(!phoneValidation(phonenumber)){
                setIsPhonenumberError(true)
            }
        }
        }
    
        function handleFirstName(e){
            setIsFirstNameError(false)
        setFirstName(e.target.value)
        }
        function handleLastName(e){
            setLastName(e.target.value)
        }
        function handleEmail(e){
            
            noSpaceValidation(e.target.value)&&
            setEmail(e.target.value)
            setIsEmailError(false)
        }
        function handlePhonenumber(e){
            setIsPhonenumberError(false)
            phoneOnChange(e.target.value) && setPhonenumber(e.target.value)
            
        }
        function handleMessage(e){
            
            setMessage(e.target.value)
        }
    return(
        <>
            <div className="page-content">
                <PageLayout desc={false} pageTitle="Contact Us" />
                <section className="content-inner contact-form-wraper style-1">
			        <div className="container">
                        <div className="row align-items-center">
                        
                                
                            <div className="col-xl-7 col-lg-7">
                                <div className="contact-box">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="mb-4">
                                                <h2 className="mb-0">Get In touch</h2>
                                                <p className="mb-0 font-18 text-primary">We are here for you. How we can help?</p>
                                            </div>
                                            <form className="dzForm" onSubmit={sendMail} >
                                                <div className="dzFormMsg"></div>
                                              
                                                
                                                <div className="row">
                                                    <div className="col-xl-6 mb-3 mb-md-4">
                                                        <input name="dzFirstName"  type="text" className="form-control" placeholder="First Name" value={firstName}  onChange={handleFirstName}/>
													{isFirstNameError &&	<p style={{color:'red',fontSize:'12px'}}>Please enter name more than 2 character</p>}
                                                    </div>
                                                    <div className="col-xl-6 mb-3 mb-md-4">
                                                        <input name="dzLastName" type="text" className="form-control" placeholder="Last Name" value={lastName}onChange={handleLastName}/>
                                                    </div>
                                                    <div className="col-xl-6 mb-3 mb-md-4">
                                                        <input name="dzEmail"  type="text" className="form-control" placeholder="Email Address" value={email} onChange={handleEmail} />
													{isEmailError &&	<p style={{color:'red',fontSize:'12px'}}>Please enter valid email</p>}
                                                    </div>
                                                    <div className="col-xl-6 mb-3 mb-md-4">
                                                       
													    <input name="dzPhoneNumber"  type="text" className="form-control" placeholder="Phone No." value={phonenumber} onChange={handlePhonenumber}/>
														{isPhonenumberError &&<p style={{color:'red',fontSize:'12px'}}>Please enter valid phonenumber</p>}
                                                    </div>
                                                    <div className="col-xl-12 mb-3 mb-md-4">
                                                        <textarea name="dzMessage"  className="form-control" placeholder="Message" value={message} onChange={handleMessage}></textarea>
														
                                                    </div>
                                                    {/* <div className="col-xl-12 mb-3 mb-md-4">
                                                        <div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
                                                        <input className="form-control d-none" style="display:none;" data-recaptcha="true"  data-error="Please complete the Captcha" />
                                                    </div> */}
                                                    <div className="col-xl-12">
                                                        <button name="submit" type="submit" value="Submit" className="btn btn-primary" >{isSending ? (<CircularProgress size={15} color="inherit" />):"Submit"}</button>								
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-5 m-b30">
                                <div className="info-box">
                                    <div className="info">
                                        <h2>Contact Information</h2>
                                        <p className="font-18">Fill up the form and our team will get back to you within 24 hours.</p>
                                    </div>
                                    
                                    <div className="widget widget_about">
                                        <div className="widget widget_getintuch">
                                            <ul>
                                                <li>
                                                    <i className="fa fa-phone"></i>
                                                    <span>+16479954462</span> 
                                                </li>
                                                <li>
                                                    <i className="fa fa-envelope"></i> 
                                                    <span>pinewoodconstruction62@hotmail.com</span>
                                                </li>
                                                <li>
                                                    <i className="fas fa-map-marker-alt"></i>
                                                    <span>Brampton, Ontario, Canada </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                    <div className="social-box dz-social-icon style-3">
                                        <h6>Our Socials</h6>
                                        <ul className="social-icon">
                                            <li><a className="social-btn" target="_blank" rel="noreferrer" href="https://instagram.com/pinewoodconstruction_?igshid=NGVhN2U2NjQ0Yg=="><i className="fa-brands fa-instagram"></i></a></li>
                                            <li><a className="social-btn" target="_blank" rel="noreferrer" href="https://www.tiktok.com/@pinewoodconstruction?_t=8hPaqghwty1&_r=1"><i className="fa-brands fa-tiktok"></i></a></li>
                                          
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default ContactUs;