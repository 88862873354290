import React, { useEffect, useState } from 'react';

import PageLayout from './../layouts/PageLayout';
import PriceBlog from './../components/About/PriceBlog';

import Shap1 from './../assets/images/home-banner/shape1.png';
import Shap3 from './../assets/images/home-banner/shape3.png';
import Rating from '@mui/material/Rating';
import { Link } from 'react-router-dom';
import blog1 from"../assets/images/blog/pic1.jpg"
import Swal from "sweetalert"

function Pricing(){
    const[value,setValue] = useState("1")
    const [firstName,setFirstName] = useState("")
    const [ message,setMessage] = useState("")
    

    function handleRatingValue(e){
        console.log(e.target.value)
        setValue(e.target.value)
    }
    function handleFirstName(e){
      
    setFirstName(e.target.value)
    }

    function sendReview (e){
        e.preventDefault()
        const currentDateTime = new Date();
        const year = currentDateTime.getFullYear();
const month = (currentDateTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
const day = currentDateTime.getDate().toString().padStart(2, '0');

const dateFormatted = `${year}-${month}-${day}`;

// Get the current time in HH:MM:SS format
const hours = currentDateTime.getHours().toString().padStart(2, '0');
const minutes = currentDateTime.getMinutes().toString().padStart(2, '0');
const seconds = currentDateTime.getSeconds().toString().padStart(2, '0');

const timeFormatted = `${hours}:${minutes}:${seconds}`;

// Create a full date-time string
const dateTimeString = `${dateFormatted} ${timeFormatted}`;
        const data ={
value:value,
  name:firstName,
  message:message,
  time:dateTimeString

        }
       
    const res = fetch("https://pine-d8386-default-rtdb.firebaseio.com/data/myData.json",
    {  method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body:JSON.stringify(data)
    }
      )
  
      if(res){
        Swal({
            title: "Thanks ",
            text: "This will help us to grw",
            icon: "success"
          });
     setFirstName("")
     setMessage("")
      }else{

      }
    }
    function handleMessage(e){
            
        setMessage(e.target.value)
    }

    useEffect(()=>{
        fetchData()
    },[])
    const fetchData = async () => {
        try {
          const response = await fetch("https://pine-d8386-default-rtdb.firebaseio.com/data.json");
      
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
      
          const data = await response.json();
          console.log(data);
          // Handle the retrieved data as needed
        } catch (error) {
          console.error('Error fetching data:', error.message);
        }
      };
    return(
        <>
          
            <div className="page-content">
                <PageLayout desc={"Please share reviews and help us to grow"} pageTitle="Reviews" />
                <section className="content-inner contact-form-wraper style-1">
			        <div className="container">
                        <div className="row align-items-center">
                        <div className="col-xl-7 col-lg-7">
                                <div className="contact-box">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="mb-4">
                                                <h2 className="mb-0">Add Reviews</h2>
                                                <p className="mb-0 font-18 text-primary">Help us to grow. Please add review</p>
                                            </div>
                                            <form className="dzForm" onSubmit={sendReview}>
                                            <div className="col-xl-6 mb-3 mb-md-4" style={{display:'flex',}}>
                                                    <span> Rating (1 to 5) - </span> &nbsp;  &nbsp; <Rating  name="simple-controlled"  value={value}  onChange={handleRatingValue}/>
                                                    </div>
                                                <div className="dzFormMsg"></div>
                                         
                                                
                                                <div className="row">
                                                
                                                    <div className="col-xl-6 mb-3 mb-md-4">
                                                        <input name="dzFirstName"  type="text" className="form-control" placeholder="Name"  value={firstName}  onChange={handleFirstName}/>
                                                    </div>
                                                    {/* <div className="col-xl-6 mb-3 mb-md-4">
                                                        <input name="dzLastName" type="text" className="form-control" placeholder="Last Name" />
                                                    </div>
                                                    <div className="col-xl-6 mb-3 mb-md-4">
                                                        <input name="dzEmail"  type="text" className="form-control" placeholder="Email Address" />
                                                    </div>
                                                    <div className="col-xl-6 mb-3 mb-md-4">
                                                        <input name="dzPhoneNumber"  type="text" className="form-control" placeholder="Phone No." />
                                                    </div> */}
                                                    
                                                    <div className="col-xl-12 mb-3 mb-md-4">
                                                        <textarea name="dzMessage"  className="form-control" placeholder="Message"value={message} onChange={handleMessage}></textarea>
                                                    </div>
                                                    {/* <div className="col-xl-12 mb-3 mb-md-4">
                                                        <div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
                                                        <input className="form-control d-none" style="display:none;" data-recaptcha="true"  data-error="Please complete the Captcha" />
                                                    </div> */}
                                                   
                                                    <div className="col-xl-12">
                                                        <button name="submit" type="submit" value="Submit" className="btn btn-primary">Submit </button>								
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-5 m-b30">
                                <div className="info-box">
                                    <div className="info">
                                        <h2>Recent Reviews</h2>
                                        <p className="font-18">Followings are reviews we got from customers</p>
                                    </div>
                                  

                                    <div className="dz-info">
                        <div className="dz-meta">
                            <ul>
                                <li className="post-author">
                                    <Link to={"#"}>
                                       
                                        <span>Henry Klasen</span>
                                    </Link>
                                </li>
                                <li className="post-date"><Link to={"#"}> 18 November 2023</Link></li>
                                <p className="m-b0">{"Great work by professionals"}</p>
                        <Rating readOnly value ={4}/>
                            </ul>
                           
                        </div>
                        <div className="dz-meta">
                            <ul>
                                <li className="post-author">
                                    <Link to={"#"}>
                                       
                                        <span>Rodreges</span>
                                    </Link>
                                </li>
                                <li className="post-date"><Link to={"#"}> 12 May 2023</Link></li>
                                <p className="m-b0">{"My strest looks good"}</p>
                        <Rating readOnly value ={4}/>
                            </ul>
                           
                        </div>
                         <div className="dz-meta">
                            <ul>
                                <li className="post-author">
                                    <Link to={"#"}>
                                       
                                        <span>Mathew Noah</span>
                                    </Link>
                                </li>
                                <li className="post-date"><Link to={"#"}> 11 April 202</Link></li>
                                <p className="m-b0">{"Great work by pinewood team , loved it"}</p>
                        <Rating readOnly value ={5}/>
                            </ul>
                           
                        </div>
                        <div className="dz-meta">
                            <ul>
                                <li className="post-author">
                                    <Link to={"#"}>
                                       
                                        <span>Rajinder Kuamr</span>
                                    </Link>
                                </li>
                                <li className="post-date"><Link to={"#"}> 02 Januaray 2023</Link></li>
                                <p className="m-b0">{"My door was giving a problem , thwy fixed that loved it "}</p>
                        <Rating readOnly value ={5}/>
                            </ul>
                           
                        </div>
                      
                     
                    </div>
                                </div>
                            </div>
                                
                           
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export  default Pricing;