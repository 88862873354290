import React,{useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Dropdown} from 'react-bootstrap';

//components
import BannerCard from './../components/Home/BannerCard';
import OneStop from './../components/Home/OneStop';
import RecentNews from './../components/Home/RecentNews';

//images
import baner1 from './../assets/images/home-banner/img1.png';
import baner2 from './../assets/images/home-banner/img2.png';
import Shape1 from './../assets/images/home-banner/shape1.png';
import Shape3 from './../assets/images/home-banner/shape3.png';
import wallet from './../assets/images/icons/wallet.svg';
import friend from './../assets/images/icons/friend.svg';

import coin1 from './../assets/images/coins/coin1.png';
import coin3 from './../assets/images/coins/coin3.png';
import coin4 from './../assets/images/coins/coin4.png';

import bloglg from './../assets/images/blog/blog-ig.png';
import avatar3 from './../assets/images/avatar/avatar3.jpg';

import emailjs from "@emailjs/browser"
import { emailValidation, firstNameValidation, noSpaceValidation, phoneOnChange, phoneValidation } from '../validation';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from "sweetalert"
const trustBlog = [
	{image: "bi bi-hammer", title:'Windows and Doors',decr:"Windows and doors are critical elements in any building, serving both functional and aesthetic purposes. They provide natural light, ventilation, and access while enhancing the overall design "}, 
	{image: "bi bi-house-door", title:'Home Construction',decr:"Home construction is the process of building a residence, from foundation to roof. It involves careful planning, design, and coordination of materials and labor.  "}, 
];

function Home(){
 const [firstName,setFirstName] = useState("")
 const[lastName,setLastName] =useState("")
 const [email,setEmail] = useState("")
 const [phonenumber,setPhonenumber] = useState()
 const [ message,setMessage] = useState("")
 const [isSending,setIsSending] = useState(false)
 const[isFirstNameError,setIsFirstNameError] = useState(false)
 const [isEmailError,setIsEmailError] = useState(false)
 const [isPhonenumberError,setIsPhonenumberError] = useState(false)
 const [isMessageError,setIsMessageError] = useState(false)
 
	const nav = useNavigate();
    const formDetails = (e) => {
        e.preventDefault();
        nav("/contact-us");
    };
	const [selecttext, setSelectText] = useState([coin4, 'Bitcoin']);

	function sendMail (e){
	e.preventDefault()

		setIsSending(true)
    const templateParams = {
	 firstName : firstName,
 lastName:lastName,
 email : email,
 phonenumber:phonenumber,
 message : message
};


		emailjs.send('service_bkqz17m', 'template_8yd1yer',templateParams, 'Hdyje0b_WLKyH8C2y')
    .then((result) => {
		setIsSending(false)
		Swal({
			title: "Thanks",
			text: "We will get back to you ASAP!",
			icon: "success"
		  });
      setEmail("")
	  setFirstName("")
	  setLastName("")
	  setMessage("")
	  setPhonenumber("")
    }, (error) => {
		setIsSending(false)
      console.log(error.text);
    });
	
	}

	function handleFirstName(e){
		setIsFirstNameError(false)
    setFirstName(e.target.value)
	}
	function handleLastName(e){
		setLastName(e.target.value)
	}
	function handleEmail(e){
		
		noSpaceValidation(e.target.value)&&
		setEmail(e.target.value)
		setIsEmailError(false)
	}
	function handlePhonenumber(e){
		setIsPhonenumberError(false)
		phoneOnChange(e.target.value) && setPhonenumber(e.target.value)
		
	}
	function handleMessage(e){
		
		setMessage(e.target.value)
	}
	return(
		<>
			<div className="page-content">	
			<div class="overlay" style={{position:'absolute',display:'flex',height:'924px',width:'100%',backgroundColor:'#001288',opacity:'0.2'}}></div>
				<div className="main-bnr style-1">
			
					<div className="container">
						<div className="row align-items-center">
							<div className="col-12 text-center">
								
								<h1 className="" >Elevating Structures, Defining Skyline Excellence</h1>
								<Link to={"/about-us"} className="btn space-lg btn-gradient btn-shadow btn-primary " >Get Started</Link>
								{/* <ul className="image-before">
									<li className="left-img"><img src={baner1} alt="" /></li>
									<li className="right-img"><img src={baner2} alt="" /></li>
								</ul> */}
							</div>
						</div>
					</div>
					<img className="bg-shape1" src={Shape1} alt="" />
					<img className="bg-shape2" src={Shape1} alt="" />
					<img className="bg-shape3" src={Shape3} alt="" />
					<img className="bg-shape4" src={Shape3} alt="" />
				</div>
				
				<section className="clearfix section-wrapper1 bg-primary-light">
					<div className="container">
						<div className="content-inner-1">
							<div className=" text-center">
								<h2 className="title">Welcome to <span className="text-primary"> Pinewood Construction </span></h2>
								<p>We have built our expertise through our talents, values, capacity to innovate, and passion. Throughout our offices in Montréal, Québec, London, Toronto, and Vancouver, we carry out major industrial, commercial, institutional and residential construction projects across Canada and around the world. Discover our vision powered by innovation and our skilled, flexible, and committed team!</p>
							</div>
							<div className="row">
								{trustBlog.map((data, ind)=>(
									<div className="col-lg-6 m-b30"  key={ind}>
										<div className="icon-bx-wraper style-2">
											<div className="icon-media">
											<i style={{fontSize:'85px',color:'white'}} class={data.image}></i>
											</div>
											<div className="icon-content">
												<h4 className="title">{data.title}</h4>
												<p>{data.decr}</p>
												<Link  className="btn btn-primary btn-gradient btn-shadow" to={"/about-us"}>Read More</Link>
											</div>
										</div>
									</div>
								))}	
								
							</div>
						</div>
					</div>
					{/* <div className="container">
						<div className="form-wrapper-box style-1 text-center">
							<div className="section-head " >
								<h4 className="title m-t0">How to Purchase from us ?</h4>
								<p>Fill out the below form and we will contact you via email & details</p>
							</div>
							<form  className="dz-form"  onSubmit={(e) => formDetails(e)}>
								<div className="form-wrapper">
									<div className="flex-1">
										<div className="row g-3">
											<div className="col-xl-3 col-md-6 " >
												<input name="dzName" type="text" required="" placeholder="Wallet Address" className="form-control" />
											</div>
											<div className="col-xl-3 col-md-6 " >
												<Dropdown className="select-drop">
													<Dropdown.Toggle as="div" className="i-false select-drop-toggle">
														<img src={selecttext[0]} alt=""/> {selecttext[1]} <i className="fa-sharp fa-solid fa-angle-down" />
													</Dropdown.Toggle>
													<Dropdown.Menu>
														<Dropdown.Item onClick={()=>setSelectText([coin4,"Bitcoin"])}><img src={coin4} alt=""/> Bitcoin</Dropdown.Item>
														<Dropdown.Item onClick={()=>setSelectText([coin3, "Ethereum"])}><img src={coin3} alt=""/> Ethereum</Dropdown.Item>
														<Dropdown.Item onClick={()=>setSelectText([coin1, "Tether"])}><img src={coin1} alt=""/> Tether</Dropdown.Item>
													</Dropdown.Menu>

												</Dropdown>
											</div>
											<div className="col-xl-3 col-md-6 " >
												<input name="dzName" type="text" required="" placeholder="How much worth in $?" className="form-control" />
											</div>
											<div className="col-xl-3 col-md-6 ">
												<input name="dzName" type="text" required="" placeholder="Email Address" className="form-control" />
											</div>
										</div>
									</div>
									<button type="submit" className="btn btn-lg btn-gradient btn-primary btn-shadow">Get Strated</button>
								</div>
							</form>
						</div>
					</div> */}
					{/* <img className="bg-shape1" src={Shape1} alt="" /> */}
				</section>
				<section className="content-inner bg-light icon-section section-wrapper2">
					<div className="container">
						<div className="section-head text-center">
							<h2 className="title">One-stop solution to build <span className="text-primary"> Homes , Roads , Fountain wall </span> and other construction work </h2>
						</div>
						<div className="row sp60">
							<OneStop />
						</div>
						<div className="card-body">
                                            <div className="mb-4">
                                                <h2 className="mb-0">For enquiry</h2>
                                                <p className="mb-0 font-18 text-primary">We are here for you. How we can help?</p>
                                            </div>
                                            <form className="dzForm" onSubmit={sendMail} >
                                                <div className="dzFormMsg"></div>
                                              
                                                
                                                <div className="row">
                                                    <div className="col-xl-6 mb-3 mb-md-4">
                                                        <input name="dzFirstName"  type="text" className="form-control" placeholder="First Name" value={firstName}  onChange={handleFirstName}/>
													{isFirstNameError &&	<p style={{color:'red',fontSize:'12px'}}>Please enter name more than 2 character</p>}
                                                    </div>
                                                    <div className="col-xl-6 mb-3 mb-md-4">
                                                        <input name="dzLastName" type="text" className="form-control" placeholder="Last Name" value={lastName}onChange={handleLastName}/>
                                                    </div>
                                                    <div className="col-xl-6 mb-3 mb-md-4">
                                                        <input name="dzEmail"  type="text" className="form-control" placeholder="Email Address" value={email} onChange={handleEmail} />
													{isEmailError &&	<p style={{color:'red',fontSize:'12px'}}>Please enter valid email</p>}
                                                    </div>
                                                    <div className="col-xl-6 mb-3 mb-md-4">
                                                       
													    <input name="dzPhoneNumber"  type="text" className="form-control" placeholder="Phone No." value={phonenumber} onChange={handlePhonenumber}/>
														{isPhonenumberError &&<p style={{color:'red',fontSize:'12px'}}>Please enter valid phonenumber</p>}
                                                    </div>
                                                    <div className="col-xl-12 mb-3 mb-md-4">
                                                        <textarea name="dzMessage"  className="form-control" placeholder="Message" value={message} onChange={handleMessage}></textarea>
														
                                                    </div>
                                                    {/* <div className="col-xl-12 mb-3 mb-md-4">
                                                        <div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
                                                        <input className="form-control d-none" style="display:none;" data-recaptcha="true"  data-error="Please complete the Captcha" />
                                                    </div> */}
                                                    <div className="col-xl-12">
                                                        <button name="submit" type="submit" value="Submit" className="btn btn-primary" onClick={sendMail}>{isSending ? (<CircularProgress size={15} color="inherit" />):"Submit"}</button>								
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
					</div>
					{/* <img className="bg-shape1" src={Shape1} alt="" /> */}
				
				</section>
				
			</div>		
		</>
	)
} 
export default Home;