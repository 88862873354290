import React,{useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import ModalVideo from 'react-modal-video'

import PageLayout from './../layouts/PageLayout';
import PriceBlog from './../components/About/PriceBlog';
import RecentNews from './../components/Home/RecentNews';

//Images
import Shape1 from './../assets/images/home-banner/shape1.png';
import Shape3 from './../assets/images/home-banner/shape3.png'; 

import about2 from './../assets/images/whatwedo/door.JPG';
import about3 from './../assets/images/whatwedo/sideentarce.JPG';
import about4 from './../assets/images/whatwedo/stampedCon.JPG';
import about1 from './../assets/images/whatwedo/stampedCon.JPG';

import videobox from './../assets/images/whatwedo/aboutsus.webp';

import bloglg from './../assets/images/whatwedo/fountainwall.jpg';
import avatar3 from './../assets/images/avatar/avatar3.jpg';
import emailjs from "@emailjs/browser"
import { emailValidation, firstNameValidation, noSpaceValidation, phoneOnChange, phoneValidation } from '../validation';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from "sweetalert"
const ImageBox = ({image, changeClass}) =>{
    return(
        
        <div className="col-6">
            <div className={`image-box ${changeClass}`}>
                <img src={image} alt="" />
            </div>
        </div>
        
    )
}

function AboutUs(){
    const [firstName,setFirstName] = useState("")
    const[lastName,setLastName] =useState("")
    const [email,setEmail] = useState("")
    const [phonenumber,setPhonenumber] = useState()
    const [ message,setMessage] = useState("")
    const [isSending,setIsSending] = useState(false)
    const[isFirstNameError,setIsFirstNameError] = useState(false)
    const [isEmailError,setIsEmailError] = useState(false)
    const [isPhonenumberError,setIsPhonenumberError] = useState(false)
    const [isMessageError,setIsMessageError] = useState(false)
    const nav = useNavigate();
    const formDetails = (e) => {
        e.preventDefault();
        nav("/contact-us");
    };
    function sendMail (e){
        e.preventDefault()
        if(firstName.length > 2 && emailValidation(email) && phoneValidation(phonenumber)  ){
            setIsSending(true)
        const templateParams = {
         firstName : firstName,
     lastName:lastName,
     email : email,
     phonenumber:phonenumber,
     message : message
    };
    
    
            emailjs.send('service_bkqz17m', 'template_8yd1yer',templateParams, 'Hdyje0b_WLKyH8C2y')
        .then((result) => {
            setIsSending(false)
            Swal({
                title: "Thanks",
                text: "We will get back to you ASAP!",
                icon: "success"
              });
          setEmail("")
          setFirstName("")
          setLastName("")
          setMessage("")
          setPhonenumber("")
        }, (error) => {
            setIsSending(false)
          console.log(error.text);
        });
        }else{
            if(!firstNameValidation(firstName)){
                setIsFirstNameError(true)
            }else if(!emailValidation(email)){
                setIsEmailError(true)
            }else if(!phoneValidation(phonenumber)){
                setIsPhonenumberError(true)
            }
        }
        }
    
        function handleFirstName(e){
            setIsFirstNameError(false)
        setFirstName(e.target.value)
        }
        function handleLastName(e){
            setLastName(e.target.value)
        }
        function handleEmail(e){
            
            noSpaceValidation(e.target.value)&&
            setEmail(e.target.value)
            setIsEmailError(false)
        }
        function handlePhonenumber(e){
            setIsPhonenumberError(false)
            phoneOnChange(e.target.value) && setPhonenumber(e.target.value)
            
        }
        function handleMessage(e){
            
            setMessage(e.target.value)
        }
    const [isOpen, setOpen] = useState(false)
    return(
        <>
            <div className="page-content">
                <PageLayout pageTitle="About Us" />               
                <section className="content-inner about-sec bg-primary-light">
                    <div className="container">
                        <div className="row about-bx2 style-1 align-items-center">
                            <div className="col-lg-6">
                                <div className="dz-media">
                                    <div className="row align-items-end">                                        
                                        <ImageBox image={about4} changeClass="image-box-1" />
                                        <ImageBox image={about2} changeClass="image-box-2" />                                      
                                    </div>
                                    <div className="row">                                        
                                        <ImageBox image={about3} changeClass="image-box-3" />
                                        <ImageBox image={about1} changeClass="image-box-4" />                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 about-content ps-lg-5 m-b30">
                                <div className="section-head">
                                    <h2 className="title">Clearly communicate what sets your company apart from the competition</h2>
                                    <p className="m-0 lh-base">We are an independent construction company that is committed to working with you to gain the results you want.</p>
                                </div>
                                <Link to={"/contact-us"} className="btn btn-lg btn-primary btn-shadow text-uppercase">Contact Us</Link>
                            </div>
                        </div>
                    </div>                    
                    <img className="bg-shape1" src={Shape1} alt="" />
					<img className="bg-shape2" src={Shape3} alt="" />
					<img className="bg-shape3" src={Shape3} alt="" />
					<img className="bg-shape4" src={Shape3} alt="" />
                </section>
                <section className="content-inner p-0 bg-primary-light video-bx-wrapper">
                    <img className="bg-shape1" src={Shape1} alt="" />
                    <div className="container wow fadeInUp" data-wow-delay="0.4s">
                        <div className="video-bx style-1">
                            <div className="video-media">
                                <img src={videobox} alt="" />
                                <Link to={"#"} className="popup-youtube play-icon" onClick={()=> setOpen(true)}>
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.25 3.75L23.75 15L6.25 26.25V3.75Z" stroke="#9467FE" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content-inner bg-light pricing-plan-wrapper2">
                    <img className="bg-shape2" src={Shape1} alt="" />
                    <div className="container">
                        {/* <div className="section-head text-center">
                            <h2 className="title">Awesome Pricing Plan for <br /> Cryptocurrency Business</h2>
                        </div> */}
                        {/* <div className="row justify-content-center">
                            <PriceBlog />
                        </div> */}
                    </div>    
                </section>
                <section className="form-sec bg-light">
                    <img className="bg-shape2" src={Shape1} alt="" />

                    <div className="container">
                        <div className="bg-primary form-wrapper1 style-1">
                            <div className="row align-items-center">
                                <div className="col-xl-3 wow fadeInUp" data-wow-delay="0.2s">
                                    <div className="section-head">
                                        <h5 className="sub-title text-white">Contact US</h5>
                                        <h2 className="title text-white">For any query</h2>
                                    </div>
                                </div>
                                <div className="col-xl-9">
                                    <form className="dzForm"  onSubmit={sendMail}>
                                        <div className="dzFormMsg"></div>
                                            <input type="hidden" className="form-control" name="dzToDo" value="Contact" />
                                            <input type="hidden" className="form-control" name="reCaptchaEnable" value="0" />

                                            <div className="row">
                                                    <div className="col-md-4 col-sm-6 m-b30 wow fadeInUp" data-wow-delay="0.1s">
                                                        <input name="dzFirstName"  type="text" className="form-control" placeholder="First Name" value={firstName}  onChange={handleFirstName}/>
													{isFirstNameError &&	<p style={{color:'white',fontSize:'12px'}}>Please enter name more than 2 character <span style={{color:'red',fontSize:'16px'}}>*</span></p>}
                                                    </div>
                                                    <div className="col-md-4 col-sm-6 m-b30 wow fadeInUp" data-wow-delay="0.2s">
                                                        <input name="dzLastName" type="text" className="form-control" placeholder="Last Name" value={lastName}onChange={handleLastName}/>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6 m-b30 wow fadeInUp" data-wow-delay="0.3s">
                                                        <input name="dzEmail"  type="text" className="form-control" placeholder="Email Address" value={email} onChange={handleEmail} />
													{isEmailError &&	<p style={{color:'white',fontSize:'12px'}}>Please enter valid email <span style={{color:'red',fontSize:'16px'}}>*</span></p>}
                                                    </div>
                                                    <div className="col-md-4 col-sm-6 m-b30 wow fadeInUp" data-wow-delay="0.4s">
                                                       
													    <input name="dzPhoneNumber"  type="text" className="form-control" placeholder="Phone No." value={phonenumber} onChange={handlePhonenumber}/>
														{isPhonenumberError &&<p style={{color:'white',fontSize:'12px'}}>Please enter valid phonenumber <span style={{color:'red',fontSize:'16px'}}>*</span></p>}
                                                    </div>
                                                    <div className="col-md-4 col-sm-6 m-b30 wow fadeInUp" data-wow-delay="0.5s">
                                                        <input name="dzMessage"  className="form-control" placeholder="Message" value={message} onChange={handleMessage}/>
														
                                                    </div>
                                                  
                                                    <div className="col-md-4 col-sm-6 m-b30 wow fadeInUp" data-wow-delay="0.6s">
                                                        <button  name="submit" type="submit" value="Submit" className="btn btn-dark btn-block h-100" >{isSending ? (<CircularProgress size={15} color="inherit" />):"Submit"}</button>								
                                                    </div>
                                                </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content-inner bg-white blog-wrapper">
                    <img className="bg-shape1" src={Shape1} alt="" />
                    <div className="container">
						<div className="row">
							<div className="col-xl-7 col-lg-12">
								<div className="section-head " >
									<h2 className="title">Recent Reviews </h2>
								</div>
								<RecentNews />
							</div>
							<div className="col-xl-5 col-lg-12 m-b30 " >
								<div className="dz-card style-2" style={{backgroundImage: "url("+ bloglg +")"}}>
									<div className="dz-category">
										<ul className="dz-badge-list">
											<li><Link to={"#"} className="dz-badge">14 Fan 2022</Link></li>
										</ul>
									</div>
									<div className="dz-info">
										<h2 className="dz-title"><Link to={"/blog-details"} className="text-white">Directly support individuals Crypto</Link></h2>
										<div className="dz-meta">
											<ul>
												<li className="post-author">
													<Link to={"#"}>
														<img src={avatar3} alt=""  className="me-2"/>
														<span>By Noare</span>
													</Link>
												</li>
												<li className="post-date"><Link to={"#"}> 12 May 2023</Link></li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
                </section>
            </div>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="sHZFs7z3PPw" onClose={() => setOpen(false)} />
        </>
    )
}
export default AboutUs;